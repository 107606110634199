import React from "react"
import Layout from "../components/layout"
import Banner from "../components/product/banner"
import About from "../components/product/about"
import Treatment from "../components/product/treatment"
import AvailableTreatments from "../components/product/available_treatments"
import HowBrigoWorks from "../components/product/how_brigo_works"
import Experts from "../components/product/experts"
import Pricing from "../components/product/pricing"
import MenHealth from "../components/product/men_health"

import FAQs from "../components/product/faqs"
import GetStarted from "../components/product/get_started"


const ProductPage = () => {
  return (
    <Layout>
      <div className="product_page">
        <div className="main-wrapper product-page">
          <div className="main-content">
            <Banner />
            <About />
            <Treatment />
            <AvailableTreatments />
            <HowBrigoWorks />
            <Experts />
            <Pricing />
            <MenHealth />
            <FAQs />
            <GetStarted />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ProductPage
