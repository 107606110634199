import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const About = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductAbout2 {
        title
        text
        image {
          gatsbyImageData
        }
        questionAnswer {
          id
          question
          answer {
            answer
          }
        }
        linkLabel
        linkUrl
      }
    }
  `)
  const {
    title,
    text,
    image,
    questionAnswer,
    linkLabel,
    linkUrl,
  } = data.contentfulProductAbout2
  return (
    <div className="about_section">
      <div className="container">
        <div className="about_inner_section">
          <div className="about_left_main_section">
            <div className="about_left_section">
              <GatsbyImage
                image={getImage(image)}
                alt="about img"
                style={{ width: "450px" }}
              />
            </div>
          </div>
          <div className="about_right_main_section">
            <span className="real_text">{text}</span>
            <div className="section_title">
              <h2>{title}</h2>
            </div>
            {questionAnswer?.map(el => (
              <div className="what_is_ed_section" key={el.id}>
                <h3>{el.question}</h3>
                <p>{el.answer.answer}</p>
              </div>
            ))}
            <div className="what_btn">
              <Link to={linkUrl} className="common-btn">
                {linkLabel}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
