import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import Img from "../../images/close-icon.svg";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const AvailableTreatments = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductAvailableProducts4 {
        title
        text
        treatments {
          id
          image {
            gatsbyImageData
          }
          title
          description {
            description
          }
          linkLabel
          linkUrl
          price
          productPopup {
            raw
          }
        }
        linkLabel
        linkUrl
      }
    }
  `);
  const {
    title,
    text,
    treatments,
    linkLabel,
    linkUrl,
  } = data.contentfulProductAvailableProducts4;
const [idx, setIdx] = useState(null)
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal(i) {
    setIdx(i)
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  const renderTreatments = (
    className,
    image,
    url,
    label,
    text,
    price,
    title,
    popup,i
  ) => (
    <div className={`explore_col_contain ${className}`}>      
      <div className="explore_col_img">
        <GatsbyImage image={getImage(image)} alt="explore element" />
      </div>
      <div className="slide_contain">
        <h3>
          <Link to={url}>{title}</Link>
        </h3>
        <p className="slide_dec">{text} </p>
        <div className="lowerProdContainer">
          <a className="learn_link" onClick={()=>openModal(i)}>
            <span>{label}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14.944"
              height="7.345"
              viewBox="0 0 14.944 7.345"
            >
              <path
                d="M10.472,12.6H.4A.4.4,0,0,0,0,13v1.868a.4.4,0,0,0,.4.4H10.472V16.8a.8.8,0,0,0,1.367.566L14.709,14.5a.8.8,0,0,0,0-1.132l-2.871-2.871a.8.8,0,0,0-1.367.566Z"
                transform="translate(0 -10.258)"
                fill="#eb4b4b"
              />
            </svg>
          </a>
          <p className="slide_price">
            <span>${price}</span> /Dose
          </p>
          {/* <button onClick={openModal}>Open Modal</button> */}
          {modalIsOpen && <Modal
            isOpen={idx === i}
            onRequestClose={closeModal}
            contentLabel={i.toString()}
            id={i.toString()}
            key={i}
            testId={i.toString()}
          >
            <button onClick={closeModal} className="modalCloseBtn">
              <img src={Img} style={{ height: "35px" }} />
            </button>
            <div className="productPopup">            
              {renderRichText(popup)}
            </div>
          </Modal>}
        </div>
      </div>
    </div>
  );
  Modal.setAppElement('#___gatsby');
  return (
    <div className="explore_main_section">
      <div className="container">
        <div className="explore_inner_section">
          <div className="explore_title">
            <p>{text}</p>
            <h2>{title}</h2>
          </div>

          <div className="explore_all">
            <div className="explore_row">
              {treatments?.map((treatment, i) => (
                <div className="explore_col" key={treatment.id}>
                  {renderTreatments(
                    i == 0
                      ? "explore_col_first"
                      : i == 1
                      ? "explore_col_sec"
                      : i == 2
                      ? "explore_col_third"
                      : "explore_col_four",
                    treatment.image,
                    treatment.linkUrl,
                    treatment.linkLabel,
                    treatment.description.description,
                    treatment.price,
                    treatment.title,
                    treatment.productPopup,i                    
                  )}
                </div>
              ))}
            </div>

            <div className="explore_btn">
              <Link to={linkUrl} className="common-btn">
                {linkLabel}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="howBrigoWorks"></div>
    </div>
  );
};

export default AvailableTreatments;
