import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const MenHealth = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductBrigoBenefitCard8(page: { eq: "ed" }) {
        text
        newImage {
          gatsbyImageData
        }
        loginText
        loginLabel
        loginUrl
        heading
        subheading
        buttonLabel
        buttonUrl
      }
    }
  `);
  const {
    text,
    newImage,
    loginText,
    loginLabel,
    loginUrl,
    heading,
    subheading,
    buttonLabel,
    buttonUrl,
  } = data.contentfulProductBrigoBenefitCard8;
  return (
    <div className="men-common-section modren_section">
      <div className="container">
        <div className="men-common-block">
          <div className="men-common-image-block">
            <div className="common-section-label">
              <p>{text}</p>
            </div>
            <div className="men-common-img">
              <GatsbyImage
                image={getImage(newImage)}
                alt="Men Modern"
                style={{ width: "100%" }}
              />
            </div>
            <div className="login-in-brigo-label">
              <p>{loginText}</p>
              <Link to={loginUrl}>
                <span>{loginLabel}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.06"
                  height="9.369"
                  viewBox="0 0 19.06 9.369"
                >
                  <path
                    d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                    transform="translate(0 -10.258)"
                    fill="#eb4b4b"
                  />
                </svg>
              </Link>
            </div>
          </div>
          <div className="men-common-content-block">
            <div className="section_title">
              <h2>{heading}</h2>
            </div>
            <div className="common-section-light-title">
              <h2>{subheading}</h2>
              {/* Can you please hook this field up as "Super Sub Heading (Smaller Text)" */}
              <p className="smallerBannerTitle"> Simple. </p>
            </div>
            <div className="common-section-btn">
              <Link to={buttonUrl} className="common-btn">
                {buttonLabel}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MenHealth;
