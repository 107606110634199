import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Experts = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductExperts6 {
        text
        image {
          gatsbyImageData
        }
        heading
        subheading1
        subheading2
        description
        linkLabel
        linkUrl
      }
    }
  `)
  const {
    text,
    image,
    heading,
    subheading1,
    subheading2,
    description,
    linkLabel,
    linkUrl,
  } = data.contentfulProductExperts6
  return (
    <div className="men-common-section good_hands_main">
      <div className="container">
        <div className="men-common-block">
          <div className="men-common-image-block">
            <div className="common-section-label">
              <p>{text}</p>
            </div>
            <div className="men-common-img">
              <GatsbyImage
                image={getImage(image)}
                alt="Men Modern"
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="men-common-content-block">
            <div className="section_title">
              <h2>{heading}</h2>
            </div>
            <div className="common-section-light-title">
              <h2>
                {subheading1} <br />
                {subheading2}{" "}
              </h2>
            </div>
            <div className="common-desc">
              <p>{description}</p>
            </div>
            <div className="common-section-btn">
              <Link to={linkUrl} className="get_link">
                <span>{linkLabel}</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.06"
                  height="9.369"
                  viewBox="0 0 19.06 9.369"
                >
                  <path
                    d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                    transform="translate(0 -10.258)"
                  />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="pricing"></div>
    </div>
  )
}

export default Experts
