import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const Treatment = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulProductTreatmentGuide3 {
        heading
        text1
        text2
        linkLabel
        linkUrl
      }
    }
  `)
  const {
    heading,
    text1,
    text2,
    linkLabel,
    linkUrl,
  } = data.contentfulProductTreatmentGuide3
  return (
    <div className="unsure_if_section">
      <div className="container">
        <div className="unsure_if_inner_section">
          <h3>{heading}</h3>
          <p>{text1} </p>
          <p>{text2}</p>
          <div className="download_learn_btn">
            <Link target="_blank" to={linkUrl} className="common-btn download_btn">
              {linkLabel}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.06"
                height="9.369"
                viewBox="0 0 19.06 9.369"
              >
                <path
                  d="M13.356,13.241H.511A.511.511,0,0,0,0,13.751v2.382a.511.511,0,0,0,.511.511H13.356V18.6a1.021,1.021,0,0,0,1.743.722l3.661-3.661a1.021,1.021,0,0,0,0-1.444L15.1,10.559a1.021,1.021,0,0,0-1.743.722Z"
                  transform="translate(0 -10.258)"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Treatment
